import { CampaignKol } from "./Models";
import { ReactComponent as IconBack } from '../../assets/ic_back.svg';

interface HeaderProps {
    title: string;
    onDiscard: () => void;
    onSave: () => void;
}

const HeaderForm: React.FC<HeaderProps> = ({ title, onDiscard, onSave }) => {
    return (
        <div style={{ marginBottom: '20px' }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: 'center',
                    height: "50px",
                    marginTop: '10px',

                    paddingLeft: "20px",
                }}
            >
                <IconBack onClick={() => {
                    onDiscard();

                }} style={{ width: "25xp", height: "25px", cursor: 'pointer' }} />
                <div style={{ fontSize: "20px", fontWeight: "normal", color: "black", marginLeft: "20px" }}>{`${title}`}</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', paddingRight: '20px' }}>
                <div
                    style={{ padding: '10px 20px', fontSize: '14px', backgroundColor: 'white', color: '#6750A4', border: 'none', borderRadius: '15px', cursor: 'pointer', marginRight: '10px' }}
                    onClick={() => {
                        onDiscard();
                    }}>
                    Discard
                </div>
                <div
                    style={{ padding: '10px 20px', fontSize: '14px', backgroundColor: '#6750A4', color: 'white', border: 'none', borderRadius: '15px', cursor: 'pointer' }}
                    onClick={() => {
                        onSave();
                    }}>
                    Save
                </div>
            </div>
        </div>
    );
}

export default HeaderForm;