import { gql } from "@apollo/client";

export const GET_WORK_ITEM_METRIC = gql`
    query WorkItemMetric($work_item_id: [uuid!]) {
        work_item_metric(where: {work_item_id: {_in: $work_item_id}}) {
            id
            work_item_id
            post_on_date
            ig_story_replies
            ig_story_link_clicks
            ig_story_sticker_taps
            ig_post_saves
            ig_post_comments
            shares
            likes
            views
            reach
            spend_usd_cents
        }
    }
`;

export const GET_WORK_ITEM_METRIC_FILES = gql`
    query GetWorkItemMetricFiles($ids: [uuid!]) {
      metric_file_metadata(where: {work_item_metric_id: {_in: $ids}}) {
        file_metadata {
          id
          url
        }
        work_item_metric_id
      }
    }
`;

export const UPDATE_WORK_ITEM_METRIC = gql`
    mutation UpdateWorkItemMetric($work_item_id: uuid!, $post_on_date: timestamptz, $ig_story_replies: bigint, $ig_story_link_clicks: bigint, $ig_story_sticker_taps: bigint, $ig_post_saves: bigint, $ig_post_comments: bigint, $shares: bigint, $likes: bigint, $views: bigint, $reach: bigint, $spend_usd_cents: bigint) {
      update_work_item_metric(where: {work_item_id: {_eq: $work_item_id}}, _set: {post_on_date: $post_on_date, ig_story_replies: $ig_story_replies, ig_story_link_clicks: $ig_story_link_clicks, ig_story_sticker_taps: $ig_story_sticker_taps, ig_post_saves: $ig_post_saves, ig_post_comments: $ig_post_comments, shares: $shares, likes: $likes, views: $views, reach: $reach, spend_usd_cents: $spend_usd_cents}) {
        
        returning {
          id
        }
      }
    }
`;

export const INSERT_WORK_ITEM_METRIC = gql`
  mutation InsertWorkItemMetric($id: uuid!, $work_item_id: uuid!, $post_on_date: timestamptz, $ig_story_replies: bigint, $ig_story_link_clicks: bigint, $ig_story_sticker_taps: bigint, $ig_post_saves: bigint, $ig_post_comments: bigint, $shares: bigint, $likes: bigint, $views: bigint, $reach: bigint, $spend_usd_cents: bigint) {
    insert_work_item_metric_one(object: {id: $id, work_item_id: $work_item_id, post_on_date: $post_on_date, ig_story_replies: $ig_story_replies, ig_story_link_clicks: $ig_story_link_clicks, ig_story_sticker_taps: $ig_story_sticker_taps, ig_post_saves: $ig_post_saves, ig_post_comments: $ig_post_comments, shares: $shares, likes: $likes, views: $views, reach: $reach, spend_usd_cents: $spend_usd_cents}) {
      id
    }
  }
`;

export const INSERT_KOL_FILE_METADATA = gql`
  mutation InsertKolFileMetadata($kol_profile_id: uuid!, $fileId: uuid!, $fileType: String) {
      insert_kol_file_metadata(objects: [{type: $fileType, file_metadata_id: $fileId, kol_profile_id: $kol_profile_id}]) {
          affected_rows
      }
  }
`;

export const REMOVE_KOL_FILE_METADATA = gql`
  mutation RemoveKolFileMetadata($kol_profile_id: uuid!, $fileId: uuid!) {
      delete_kol_file_metadata(where: {kol_profile_id: {_eq: $kol_profile_id}, file_metadata_id: {_eq: $fileId}}) {
          affected_rows
      }
  }
`;

export const INSERT_METRIC_FILE_METADATA = gql`
  mutation InsertMetricFileMetadata($file_metadata_id: uuid!, $work_item_metric_id: uuid!) {
    insert_metric_file_metadata_one(object: {file_metadata_id: $file_metadata_id, work_item_metric_id: $work_item_metric_id}) {
      work_item_metric_id
      file_metadata_id
    }
  }
`;

export const REMOVE_METRIC_FILE_METADATA = gql`
  mutation RemoveMetricFileMetadata($work_item_metric_id: uuid!, $file_metadata_id: uuid!) {
    delete_metric_file_metadata(where: {work_item_metric_id: {_eq: $work_item_metric_id}, file_metadata_id: {_eq: $file_metadata_id}}) {
      affected_rows
    }
  }
`;

export const GET_CAMPAIGNS_INFO = gql`
    query MerchantCampaignList($campaign_id: uuid!) {
  campaign(where: {id: {_eq: $campaign_id}}) {
    brand_name
    client_profile_id
    created_at
    budget_usd_cents
    display_currency
    
    description
    end_date
    id
    image_url
    name
    product_name
    recruitment_end_date
    start_date
    status
    
    tranches {
      name
      
      deliverable_specs {
        id
        quantity
        content_type_code
        requirement_description
        due_date
        title
      }
      requirements
      work_contracts {
        id
        
        kol_profile {
          id
          first_name
          last_name
          email
        }
        
        tranche {
          id
          name
        }
        work_items {
          id
          status_code
          link
          deliverable_spec {
            id
            content_type {
              code
              name
            }
            title
            quantity
            requirement_description
            due_date
          }
        }
      }
    }
  }
}

`;

export const GET_CAMPAIGN_KOL_METRICS = gql`
  query CampaignKolMetric($campaign_id: uuid!) {
  
    campaign_kol_metric(where: {campaign_id: {_eq: $campaign_id}}) {
      campaign_id
      fan_base
      kol_profile_id
      platform_handle
    }
  }
`;

export const UPDATE_CAMPAIGN_KOL_METRIC = gql`
  mutation UpdateCampaignKolMetric($campaign_id: uuid!, $kol_profile_id: uuid!, $fan_base: bigint) {
    update_campaign_kol_metric(where: {campaign_id: {_eq: $campaign_id}, kol_profile_id: {_eq: $kol_profile_id}}, _set: {fan_base: $fan_base}) {
      affected_rows
    }
  }
`;

export const GET_KOL_SOCIAL_PLATFORMS = gql`
  query KolSocialPlatforms($kol_profile_ids: [uuid!]) {
    kol_social_platform(where: {kol_profile_id: {_in: $kol_profile_ids}}) {
      kol_profile_id
      platform_username
      social_platform_code
    }
  }
`;

