import { format, parse } from "date-fns";
import { enUS } from 'date-fns/locale';
import { v4 as uuidv4 } from 'uuid';

export class CampaignKol {
    fullName: string | undefined;
    deliverableStatus: string | undefined;
    insightReportStatus: string | undefined;
    email: string | undefined;
    warning: string | undefined;

    workItems: WorkItemData[] | undefined;

    toString = () => {
        return JSON.stringify(this);
    }
}

export class WorkItem {
    id: string | undefined;
    postingLink: string | undefined;
    platform: string | undefined;
    type: string | undefined;
    code: string | undefined;
    quantity: number | undefined;
    status: string | undefined;
    description: string | undefined;
}

export class WorkItemKolProfile {
    kol_profile_id: string | undefined;
    platform_handle: string | undefined;
    fanbase: number | undefined;
    kol_platforms: KolSocialPlatform[] | undefined;
}

export class KolSocialPlatform {
    platform_handle: string | undefined;
    username: string | undefined;

}

export class KolInsight {
    metricId: string | undefined;
    reach: number | undefined;
    view: number | undefined;
    like: number | undefined;
    comment: number | undefined;
    share: number | undefined;
    save: number | undefined;
    spend: number | undefined;
    postOnDate: string | undefined;
    sticker_tap: number | undefined;
    link_click: number | undefined;
    reply: number | undefined;
    images: InsightImage[] | undefined;

    imagesToRemove: InsightImage[] | undefined;
    isLocal: boolean | undefined;

}

export class LocalInsightFile {
    file: File | undefined;
    data: string | undefined;
}

export class InsightImage {
    id: string | undefined;
    url: string | undefined;
    file: LocalInsightFile | undefined;

}

export class WorkItemData {
    kol: WorkItemKolProfile | undefined;
    workItem: WorkItem | undefined;
    kolInsight: KolInsight | undefined;
}

export enum ContentType {
    post,
    story,
    livestream,
    none,
    unknown
}

export const getWorkItemType = (code: string | undefined): ContentType => {

    let result = ContentType.unknown;
    if (code === "ig_reels" || code === "ig_photo" || code === "ig_multipost" || code === "tt_video" || code === "xhs_video" || code === "xhs_multipost" || code === "l8_video" || code === "l8_multipost" || code === "youtube_video" || code === "youtube_shorts" || code === "fb_photo" || code === "fb_video" || code === "fb_multipost") {
        result = ContentType.post;
    }
    if (code === "ig_story_highlights" || code === "ig_story" || code === "tt_story") {
        result = ContentType.story;
    }
    if (code === "content_usage" || code === "event_attendance" || code === "video_shoot" || code === "photo_shoot") {
        result = ContentType.none;
    }
    if (code === "livestream") {
        result = ContentType.livestream;
    }
    return result;
}

export const getTierFromFanbase = (fanbase: number): string => {
    if (fanbase >= 1000000) return 'Elite';
    if (fanbase >= 500000) return 'Mega';
    if (fanbase >= 100000) return 'Macro';
    if (fanbase >= 50000) return 'Mid B';
    if (fanbase >= 15000) return 'Mid C';
    if (fanbase >= 5000) return 'Micro';
    if (fanbase >= 1000) return 'Nano';
    return 'KOC';
};

export const getWorkItemPlatform = (code: string) => {
    if (code === "fb_video" || code === "fb_multipost" || code === "fb_photo") {
        return "FACEBOOK";
    }
    if (code === "ig_story_highlights" || code === "ig_photo" || code === "ig_multipost" || code === "ig_reels" || code === "ig_story") {
        return "INSTAGRAM";
    }
    if (code === "youtube_video" || code === "youtube_shorts") {
        return "YOUTUBE";
    }
    if (code === "xhs_video" || code === "xhs_multipost") {
        return "XIAOHONGSHU";
    }
    if (code === "tt_story" || code === "tt_video") {
        return "TIKTOK";
    }
    if (code === "l8_multipost" || code === "l8_video") {
        return "LEMON8";
    }
    return "";
}

export const postOnDateText = (data: WorkItemData): string => {
    if (data.kolInsight?.postOnDate) {
        const date = parse(data.kolInsight?.postOnDate?.substring(0, 10) ?? '', 'yyyy-MM-dd', new Date());
        const formattedDate = format(date, "do MMM, yy", { locale: enUS });
        return formattedDate;
    }
    return "";
}

export const postOnDateValue = (data: WorkItemData): Date => {
    if (data.kolInsight?.postOnDate) {
        return parse(data.kolInsight?.postOnDate?.substring(0, 10) ?? '', 'yyyy-MM-dd', new Date());
    }
    return new Date();
}

export const generateUUID = (): string => {
    return uuidv4();
};

export const loadKolWorkItem = (): CampaignKol | undefined => {
    const campaignKol = localStorage.getItem('campaignKol');
    if (campaignKol) {
        return JSON.parse(campaignKol);
    }
    return undefined;
}

export const clearKolWorkItem = () => {
    // Clear the CampaignKol object from local storage
    localStorage.removeItem('campaignKol');
}

export const isEmpty = (insight: KolInsight) => {
    const emptyReach = insight.reach === 0 || insight.reach === undefined;
    const emptyView = insight.view === 0 || insight.view === undefined;
    const emptyLike = insight.like === 0 || insight.like === undefined;
    const emptyComment = insight.comment === 0 || insight.comment === undefined;
    const emptyShare = insight.share === 0 || insight.share === undefined;
    const emptySave = insight.save === 0 || insight.save === undefined;
    const emptySpend = insight.spend === 0 || insight.spend === undefined;
    const emptyStickerTap = insight.sticker_tap === 0 || insight.sticker_tap === undefined;
    const emptyLinkClick = insight.link_click === 0 || insight.link_click === undefined;
    const emptyReply = insight.reply === 0 || insight.reply === undefined;
    const emptyImages = insight.images === undefined || insight.images.length === 0;
    return emptyImages && emptyReach && emptyView && emptyLike && emptyComment && emptyShare && emptySave && emptySpend && emptyStickerTap && emptyLinkClick && emptyReply;
}

export const formatDateToApiData = (date: Date) => {
    return format(date, "yyyy-MM-dd'T'HH:mm:ss");
}

