import React, { useEffect } from 'react';
import './ContentMetricForm.css'; // Import the CSS file
import ContentMetricsField from './ContentMetricsField';

interface ContentMetricsFormProps {
  title: string;
  kol: string;
  kolProfileId: string;
  fanbase: number;
  tier: string;
  onFanbaseChange: (kolProfileId: string, fanbase: number) => void;
}

const ContentMetricsForm: React.FC<ContentMetricsFormProps> = ({ title, kol, kolProfileId, fanbase, tier, onFanbaseChange }) => {
  return (
    <div className="contentMetricsArea">
      <div className="areaTitle">{title}</div>
      <div className="contentField">
        <ContentMetricsField
          title="Platform handle"
          content={kol}
          contentType="text"
          enableEdit={false}
          type="KOL Name"
          handleInputChange={(e, type) => { }}
          iconPath={null}
        />
      </div>
      <div className="contentRow">
        <div className="contentField">
          <ContentMetricsField
            title="Fanbase"
            content={fanbase.toString()}
            enableEdit={true}
            type="Fanbase"
            iconPath={null}
            contentType="number"
            handleInputChange={(e, type) => {
              const newFanbase = parseInt(e.target.value);
              onFanbaseChange(kolProfileId, newFanbase);
            }}
          />
        </div>
        <div className="contentField" style={{ marginLeft: '15px' }}>
          <ContentMetricsField
            title="Tier"
            content={tier}
            enableEdit={false}
            contentType="text"
            iconPath={null}
            type="Tier"
            handleInputChange={(e, type) => { }}
          />
        </div>
      </div>
    </div>
  );
};

export default ContentMetricsForm;
