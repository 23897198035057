import { Edit, FunctionField, SimpleForm, TextInput } from "react-admin"
import styled from "styled-components"
import { formatCampaignDate, formatCampaignPeriod } from "../utils/time"
import { centsToUsd } from "../utils/format"
import * as Tabs from "@radix-ui/react-tabs"
import { Fragment } from "react/jsx-runtime"
import { useEffect, useState, forwardRef } from "react"
import { DefaultOptions, useApolloClient } from "@apollo/client"
import {
  GET_CAMPAIGNS_INFO,
  GET_WORK_ITEM_METRIC,
  GET_KOL_SOCIAL_PLATFORMS,
  GET_CAMPAIGN_KOL_METRICS,
  UPDATE_WORK_ITEM_METRIC,
  GET_WORK_ITEM_METRIC_FILES,
  INSERT_WORK_ITEM_METRIC,
  INSERT_KOL_FILE_METADATA,
  REMOVE_KOL_FILE_METADATA,
  INSERT_METRIC_FILE_METADATA,
  REMOVE_METRIC_FILE_METADATA,
  UPDATE_CAMPAIGN_KOL_METRIC,
} from "../queries/insights"
import SearchIcon from "@mui/icons-material/Search"
import "../index.css"
import {
  CampaignKol,
  clearKolWorkItem,
  getWorkItemPlatform,
  InsightImage,
  KolInsight,
  loadKolWorkItem,
  generateUUID,
  postOnDateValue,
  WorkItem,
  WorkItemData,
  WorkItemKolProfile,
  isEmpty,
  KolSocialPlatform,
  formatDateToApiData,
} from "./insights/Models"
import { WorkItemsForm } from "./insights/WorkItemsForm"
import { set } from "lodash"
import { ENV } from "../configs/env"
import { LoadingIndicator } from "../payouts/ActualPayouts"
import { toast } from "../utils/toast"

const UPLOAD_DOCUMENT_ENDPOINT = `${ENV.REACT_APP_WEBHOOK_URL}/files`

enum TabId {
  Information = "Information",
  Insights = "Insights Report",
}

const TABS = [
  {
    id: TabId.Information,
    label: "Information",
  },
  {
    id: TabId.Insights,
    label: "Insights Report",
  },
]

export const CampaignForm = () => {
  const [campaignKols, setCampaignKols] = useState<CampaignKol[]>([])
  const [loading, setLoading] = useState(false)
  const [focusedTab, setFocusedTab] = useState<TabId>(TabId.Information)

  const client = useApolloClient()

  useEffect(() => {
    const defaultOptions: DefaultOptions = {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    }
    client.defaultOptions = defaultOptions
  }, [])

  const uploadFile = async (file: any) => {
    const formData = new FormData()
    formData.append("file", file)
    const response = await fetch(UPLOAD_DOCUMENT_ENDPOINT, {
      method: "POST",
      body: formData,
    })
    if (!response.ok) {
      throw new Error("File upload failed")
    }

    const data = await response.json()
    return data
  }

  const insertOrUpdateWorkItemMetric = async (workItemData: WorkItemData) => {
    if (!workItemData.kolInsight) {
      return
    }
    if (workItemData.kolInsight.isLocal === true) {
      return
    }
    const insight = workItemData.kolInsight
    let workItemMetricId = insight.metricId ?? ""
    if (workItemMetricId.length === 0) {
      return
    }
    await client.mutate({
      mutation: UPDATE_WORK_ITEM_METRIC,
      variables: {
        work_item_id: workItemData.workItem?.id,
        post_on_date: formatDateToApiData(postOnDateValue(workItemData)),
        ig_story_replies: workItemData.kolInsight?.reply ?? 0,
        ig_story_link_clicks: workItemData.kolInsight?.link_click ?? 0,
        ig_story_sticker_taps: workItemData.kolInsight?.sticker_tap ?? 0,
        ig_post_saves: workItemData.kolInsight?.save ?? 0,
        ig_post_comments: workItemData.kolInsight?.comment ?? 0,
        shares: workItemData.kolInsight?.share ?? 0,
        likes: workItemData.kolInsight?.like ?? 0,
        views: workItemData.kolInsight?.view ?? 0,
        reach: workItemData.kolInsight?.reach ?? 0,
        spend_usd_cents: workItemData.kolInsight?.spend ?? 0,
      },
    })
    //Upload images
    let localImages = workItemData.kolInsight?.images ?? []
    for (const image of localImages) {
      if (image.file && image.file.file) {
        const data = await uploadFile(image.file.file)
        await client.mutate({
          mutation: INSERT_KOL_FILE_METADATA,
          variables: {
            kol_profile_id: workItemData.kol?.kol_profile_id,
            fileId: data.id,
            fileType: "insights",
          },
        })
        await client.mutate({
          mutation: INSERT_METRIC_FILE_METADATA,
          variables: {
            work_item_metric_id: workItemData.kolInsight?.metricId,
            file_metadata_id: data.id,
          },
        })
      }
    }
    //Remove images
    let imagesToRemove = workItemData.kolInsight?.imagesToRemove ?? []
    for (const image of imagesToRemove) {
      await client.mutate({
        mutation: REMOVE_KOL_FILE_METADATA,
        variables: {
          kol_profile_id: workItemData.kol?.kol_profile_id,
          fileId: image.id,
        },
      })
      await client.mutate({
        mutation: REMOVE_METRIC_FILE_METADATA,
        variables: {
          work_item_metric_id: workItemData.kolInsight?.metricId,
          file_metadata_id: image.id,
        },
      })
    }
  }

  const updateWorkItemMetrics = async (workItems: WorkItemData[]) => {
    if (workItems.length === 0) {
      return
    }
    //update campaign kol metric
    const workItem = workItems[0]
    const url = window.location.href
    const campaignId = url.substring(url.lastIndexOf("/") + 1)
    await client.mutate({
      mutation: UPDATE_CAMPAIGN_KOL_METRIC,
      variables: {
        kol_profile_id: workItem.kol?.kol_profile_id,
        fan_base: workItem.kol?.fanbase ?? 0,
        campaign_id: campaignId,
      },
    })
    const promises = workItems.map((workItemData) => {
      return insertOrUpdateWorkItemMetric(workItemData)
    })
    await Promise.all(promises)
  }

  const fetchCampaignInfo = async (campaign_id: any, filter: string) => {
    let campaignKols: CampaignKol[] = []
    const response = await client.query({
      query: GET_CAMPAIGNS_INFO,
      variables: {
        campaign_id: campaign_id,
      },
    })
    if (!response.data.campaign || response.data.campaign.length === 0) {
      return campaignKols
    }
    let workItemsIds: string[] = []
    let workItemMetricMap = new Map()
    let kolProfileIds = new Set()
    let campaign = response.data.campaign[0]
    for (const tranche of campaign.tranches) {
      for (const workContract of tranche.work_contracts) {
        for (const workItem of workContract.work_items) {
          workItemsIds.push(workItem.id)
        }
      }
    }
    let workItemMetricResponse = await client.query({
      query: GET_WORK_ITEM_METRIC,
      variables: {
        work_item_id: workItemsIds,
      },
    })

    let campaignKolMetricResponse = await client.query({
      query: GET_CAMPAIGN_KOL_METRICS,
      variables: {
        campaign_id: campaign_id,
      },
    })

    let workItemMetricIds = []

    for (const workItemMetric of workItemMetricResponse.data.work_item_metric) {
      workItemMetricMap.set(workItemMetric.work_item_id, workItemMetric)
      workItemMetricIds.push(workItemMetric.id)
    }

    let workItemMetricFiles = await client.query({
      query: GET_WORK_ITEM_METRIC_FILES,
      variables: {
        ids: workItemMetricIds,
      },
    })

    for (const tranche of campaign.tranches) {
      for (const workContract of tranche.work_contracts) {
        let campaignKol = new CampaignKol()
        let warnings: string[] = []
        campaignKol.fullName = workContract.kol_profile.first_name + " " + workContract.kol_profile.last_name
        campaignKol.email = workContract.kol_profile.email
        let completedWorkItems = workContract.work_items.filter((workItem: any) => workItem.link !== null && workItem.link !== "")
        let deliverableStatus = completedWorkItems.length + "/" + tranche.deliverable_specs.length
        campaignKol.deliverableStatus = deliverableStatus
        kolProfileIds.add(workContract.kol_profile.id)

        for (const workItems of workContract.work_items) {
          if (workItems.status_code === "PENDING_SUBMIT" && !warnings.includes("Required to submit insights report")) {
            warnings.push("Required to submit insights report")
          } else if (
            workItems.status_code === "INSIGHTS_REJECTED" &&
            !warnings.includes("Required to re-submit insights report")
          ) {
            warnings.push("Required to re-submit insights report")
          }
        }

        let insightReportCount = 0
        for (const workItem of workContract.work_items) {
          if (workItemMetricMap.get(workItem.id)) {
            insightReportCount++
          }
        }

        campaignKol.insightReportStatus = insightReportCount + "/" + tranche.deliverable_specs.length
        campaignKol.warning = warnings.join("\n")

        //work items
        let workItems: WorkItemData[] = []
        for (const workItem of workContract.work_items) {
          let workItemData = new WorkItemData()

          let workItemKolProfile = new WorkItemKolProfile()
          workItemKolProfile.kol_profile_id = workContract.kol_profile.id

          for (const kolMetric of campaignKolMetricResponse.data.campaign_kol_metric) {
            if (kolMetric.kol_profile_id == workContract.kol_profile.id) {
              workItemKolProfile.fanbase = kolMetric.fan_base
              workItemKolProfile.platform_handle = kolMetric.platform_handle
            }
          }

          workItemData.kol = workItemKolProfile

          let wi = new WorkItem()
          wi.id = workItem.id
          wi.description = workItem.deliverable_spec.requirement_description
          wi.quantity = workItem.deliverable_spec.quantity
          wi.type = workItem.deliverable_spec.content_type.name
          wi.code = workItem.deliverable_spec.content_type.code
          wi.platform = getWorkItemPlatform(workItem.deliverable_spec.content_type.code)
          wi.postingLink = workItem.link
          wi.status = workItem.status_code
          workItemData.workItem = wi

          let kolInsight = new KolInsight()
          let images: InsightImage[] = []
          for (const workItemMetric of workItemMetricResponse.data.work_item_metric) {
            if (workItemMetric.work_item_id === workItem.id) {
              kolInsight.metricId = workItemMetric.id
              kolInsight.reach = workItemMetric.reach
              kolInsight.view = workItemMetric.views
              kolInsight.like = workItemMetric.likes
              kolInsight.comment = workItemMetric.ig_post_comments
              kolInsight.share = workItemMetric.shares
              kolInsight.save = workItemMetric.ig_post_saves
              kolInsight.spend = workItemMetric.spend_usd_cents
              kolInsight.postOnDate = workItemMetric.post_on_date
              kolInsight.sticker_tap = workItemMetric.ig_story_sticker_taps
              kolInsight.link_click = workItemMetric.ig_story_link_clicks
              kolInsight.reply = workItemMetric.ig_story_replies

              for (const workItemMetricFile of workItemMetricFiles.data.metric_file_metadata) {
                if (workItemMetricFile.work_item_metric_id === workItemMetric.id) {
                  let image = new InsightImage()
                  image.id = workItemMetricFile.file_metadata.id
                  image.url = workItemMetricFile.file_metadata.url
                  images.push(image)
                }
              }
              kolInsight.images = images
              break
            }
          }
          const isLocalInsight = kolInsight.metricId === undefined
          kolInsight.isLocal = isLocalInsight
          if (isLocalInsight) {
            kolInsight.metricId = generateUUID()
          }
          workItemData.kolInsight = kolInsight

          workItems.push(workItemData)
        }
        campaignKol.workItems = workItems

        if (
          filter === "" ||
          campaignKol.fullName?.toLowerCase().includes(filter.toLowerCase()) ||
          campaignKol.email?.toLowerCase().includes(filter.toLowerCase())
        ) {
          campaignKols.push(campaignKol)
        }
      }
    }

    const kolProfileSocialPlatforms = await client.query({
      query: GET_KOL_SOCIAL_PLATFORMS,
      variables: {
        kol_profile_ids: Array.from(kolProfileIds),
      },
    })
    for (let campaignKol of campaignKols) {
      for (let workItem of campaignKol.workItems ?? []) {
        if (workItem.kol) {
          let kolProfileId = workItem.kol.kol_profile_id
          for (let kolProfileSocialPlatform of kolProfileSocialPlatforms.data.kol_social_platform) {
            if (kolProfileSocialPlatform.kol_profile_id === kolProfileId) {
              let kolSocialPlatform = new KolSocialPlatform()
              kolSocialPlatform.platform_handle = kolProfileSocialPlatform.social_platform_code
              kolSocialPlatform.username = kolProfileSocialPlatform.platform_username
              if (!workItem.kol.kol_platforms) {
                workItem.kol.kol_platforms = []
              }
              workItem.kol.kol_platforms.push(kolSocialPlatform)
            }
          }
        }
      }
    }
    return campaignKols
  }

  const [searchQuery, setSearchQuery] = useState("")
  const debouncedSearchQuery = useDebounce(searchQuery, 500)

  const [focusedKol, setFocusedKol] = useState<CampaignKol | null>(null)

  function useDebounce(value: string, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      return () => {
        clearTimeout(handler)
      }
    }, [value, delay])

    return debouncedValue
  }

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value)
  }

  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

  useEffect(() => {
    const url = window.location.href
    const campaignId = url.substring(url.lastIndexOf("/") + 1)
    const campaignInfo = fetchCampaignInfo(campaignId, debouncedSearchQuery)

    campaignInfo.then((result) => {
      setCampaignKols(result)
    })
  }, [debouncedSearchQuery])

  const warningView = (warning: string) => {
    const emptyView = <div></div>
    const warnings = warning.split("\n")
    if (warnings.length === 0) {
      return emptyView
    } else {
      return (
        <div>
          {warnings.map((warning, index) => {
            return (
              <div key={index} style={{ color: "#CE2C17", fontSize: "11px" }}>
                {" "}
                {warning}{" "}
              </div>
            )
          })}
        </div>
      )
    }
  }

  const renderTabInformation = () => {
    return (
      <Fragment>
        <Edit>
          <SimpleForm style={{ maxWidth: 600 }} toolbar={false}>
            <h2>Campaign details</h2>
            <FunctionField
              label="Merchant name"
              render={(record: any) => (
                <div>
                  {record?.images?.map((x: any, i: number) => (
                    <img key={i} src={x?.file_metadata?.url} width={120} height={120} alt={`campaign image ${i + 1}`} />
                  ))}
                </div>
              )}
            />
            <TextInput fullWidth disabled label="Campaign name" source="name" />
            <TextInput fullWidth disabled label="Brand name" source="brand_name" />
            <TextInput fullWidth disabled label="Product name" source="product_name" />
            <TextInput fullWidth disabled label="Campaign description" source="description" />
            <OneLine>
              <FunctionField
                label="Campaign period"
                style={{ width: "100%" }}
                render={(record: any) => (
                  <TextInput
                    defaultValue={formatCampaignPeriod(record?.start_date, record?.end_date)}
                    source="campaign_period"
                    label="Campaign period"
                    disabled
                    fullWidth
                  />
                )}
              />
              {/* <TextInput
            disabled
            label="Recruitment deadline"
            source="recruitment_end_date"
          /> */}
              <FunctionField
                label="Recruitment deadline"
                style={{ width: "100%" }}
                render={(record: any) => (
                  <TextInput
                    defaultValue={formatCampaignDate(record?.recruitment_end_date)}
                    source="recruitment_deadline"
                    label="Recruitment deadline"
                    disabled
                    fullWidth
                  />
                )}
              />
            </OneLine>
            <h2>Kol requirements</h2>
            <FunctionField
              fullWidth={true}
              style={{ width: "100%" }}
              render={(record: any) => {
                return record?.tranches?.map((x: any, i: number) => (
                  <div>
                    <h3>{`Group ${i + 1}`}</h3>
                    <div style={{ paddingLeft: 20 }}>
                      <TextInput
                        defaultValue={x?.product_categories?.map((i: any) => i?.product_category?.name)?.join(", ")}
                        source="kol_affinity"
                        label="KOL affinity"
                        disabled
                        fullWidth
                        multiline={true}
                      />
                      <OneLine>
                        <TextInput
                          defaultValue={x?.min_follower_size}
                          source="min_followers"
                          label="Min followers"
                          disabled
                          fullWidth
                        />
                        <TextInput
                          defaultValue={x.max_follower_size}
                          source="max_followers"
                          label="Max followers"
                          disabled
                          fullWidth
                        />
                      </OneLine>
                      <OneLine>
                        <TextInput
                          defaultValue={x?.social_platforms?.map((i: any) => i?.social_platform?.name)?.join(", ")}
                          source="platform"
                          label="Platform"
                          disabled
                          fullWidth
                        />
                        <TextInput
                          defaultValue={x?.genders?.map((i: any) => i?.gender?.description)?.join(", ")}
                          source="gender"
                          label="Gender"
                          disabled
                          fullWidth
                        />
                      </OneLine>
                      <TextInput
                        defaultValue={x?.age_groups?.map((i: any) => i?.age_category?.name)?.join(", ")}
                        source="age_group"
                        label="Age group"
                        disabled
                        fullWidth
                      />
                      <OneLine>
                        <TextInput defaultValue={x?.number_kols} source="number_of_kols" label="Num of KOLs" disabled fullWidth />
                        <TextInput defaultValue={"Somewhere"} source="location" label="Location" disabled fullWidth />
                      </OneLine>
                      <h4>Deliverables and due date</h4>
                      <OneLine>
                        <TextInput defaultValue={centsToUsd(x?.cash_usd_cents)} source="cash" label="Cash" disabled fullWidth />
                        <TextInput
                          defaultValue={centsToUsd(x?.product_value_usd_cents)}
                          source="pik_value"
                          label="PIK value"
                          disabled
                          fullWidth
                        />
                      </OneLine>
                      <TextInput
                        defaultValue={"PIK description"}
                        source="pik_description"
                        label="PIK description"
                        disabled
                        fullWidth
                      />
                      <h4>Remuneration per KOL</h4>
                      <OneLine>
                        <TextInput
                          defaultValue={x?.deliverable_specs?.length || 0}
                          source="number_of_deliverables"
                          label="Number of deliverables"
                          disabled
                          fullWidth
                        />
                        <TextInput
                          defaultValue={x?.deliverable_specs?.map((i: any) => i?.content_type?.name).join(", ")}
                          source="type"
                          label="Type"
                          disabled
                          fullWidth
                          multiline={true}
                        />
                      </OneLine>
                      <TextInput
                        defaultValue={x?.deliverable_specs?.map((i: any) => formatCampaignDate(i?.due_date)).join(", ")}
                        source="due"
                        label="Due"
                        disabled
                        fullWidth
                      />
                      <TextInput
                        defaultValue={x?.product_description || "N/A"}
                        source="description"
                        label="Description"
                        disabled
                        fullWidth
                      />
                      <TextInput
                        defaultValue={x?.requirements || "N/A"}
                        source="other_requirements"
                        label="Other requirements"
                        disabled
                        fullWidth
                      />
                    </div>
                  </div>
                ))
              }}
            />
          </SimpleForm>
        </Edit>
      </Fragment>
    )
  }

  const renderTabInsights = () => {
    return (
      <Fragment>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "20px",
              alignItems: "center",
              padding: "10px",
              width: "350px",
              backgroundColor: "#ECE6F0",
              borderRadius: "20px",
              border: "none",
              color: "#49454F",
              fontSize: "14px",
            }}
          >
            <input
              type="text"
              className="input-no-border"
              placeholder="Search influencer"
              value={searchQuery}
              onChange={handleSearchChange}
              style={{
                width: "350px",
                padding: "10px",
                backgroundColor: "#ECE6F0",
                borderRadius: "20px",
                border: "none",
                color: "#49454F",
                fontSize: "14px",
              }}
            />
            <SearchIcon style={{ color: "#49454F" }} />
          </div>
        </div>
        <table className="table" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th style={{ width: "40%", fontSize: "12px", textAlign: "start" }}>Fullname</th>
              <th style={{ width: "15%", fontSize: "12px", textAlign: "start" }}>Deliverable Status</th>
              <th style={{ width: "15%", fontSize: "12px", textAlign: "start" }}>Insight Report Status</th>
              <th style={{ width: "20%", fontSize: "12px", textAlign: "start" }}></th>
              <th style={{ width: "10%", fontSize: "12px", textAlign: "center" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {campaignKols.map((campaignKol, index) => {
              console.log(`Warning: ${campaignKol.warning}`)
              const completedDeliverable =
                (campaignKol.deliverableStatus ?? "0/0").split("/")[0] === (campaignKol.deliverableStatus ?? "0/0").split("/")[1]
              const completedInsight =
                (campaignKol.insightReportStatus ?? "0/0").split("/")[0] ===
                (campaignKol.insightReportStatus ?? "0/0").split("/")[1]
              const deliverableDescriptionColor = completedDeliverable ? "#45CA8A" : "#5770C6"
              const insightDescriptionColor = completedInsight ? "#45CA8A" : "#5770C6"
              return (
                <tr key={index}>
                  <td style={{ color: "#6750A4", fontSize: "14px" }}>{campaignKol.fullName}</td>
                  <td>
                    <div>
                      {completedDeliverable ? (
                        <div>
                          {" "}
                          <span style={{ color: deliverableDescriptionColor, fontSize: "14px" }}> Completed </span>{" "}
                        </div>
                      ) : (
                        <div>
                          {" "}
                          <span style={{ color: deliverableDescriptionColor, fontSize: "14px" }}> Ongoing </span>{" "}
                        </div>
                      )}
                      <div style={{ color: "#717171" }}> {campaignKol.deliverableStatus} </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      {completedInsight ? (
                        <div>
                          {" "}
                          <span style={{ color: insightDescriptionColor, fontSize: "14px" }}> Completed </span>{" "}
                        </div>
                      ) : (
                        <div>
                          {" "}
                          <span style={{ color: insightDescriptionColor, fontSize: "14px" }}> Ongoing </span>{" "}
                        </div>
                      )}
                      <div style={{ color: "#717171" }}> {campaignKol.insightReportStatus} </div>
                    </div>
                  </td>
                  <td>
                    <div style={{ color: "#CE2C17", fontSize: "11px" }}> {warningView(campaignKol.warning ?? "")}</div>
                  </td>
                  <td
                    style={{ textAlign: "center", color: "#6750A4", cursor: "pointer" }}
                    onClick={() => {
                      setFocusedKol(campaignKol)
                    }}
                  >
                    EDIT
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </Fragment>
    )
  }

  return (
    <Edit>
      {!focusedKol && (
        <SimpleForm toolbar={false}>
          <Tabs.Root defaultValue={focusedTab} style={{ width: "100%" }}>
            <TabsList aria-label="Tabs">
              {TABS.map((tab) => (
                <TabsTrigger key={tab.id} value={tab.id}>
                  {tab.label}
                </TabsTrigger>
              ))}
            </TabsList>

            <TabsContent value={TabId.Information}>{renderTabInformation()}</TabsContent>
            <TabsContent value={TabId.Insights}>{renderTabInsights()}</TabsContent>
          </Tabs.Root>
        </SimpleForm>
      )}

      {focusedKol && (
        <WorkItemsForm
          campaignKol={focusedKol}
          onDiscard={() => {
            setFocusedKol(null)
          }}
          onSave={(kol) => {
            if (kol) {
              setLoading(true)
              updateWorkItemMetrics(kol.workItems ?? [])
                .then(() => {
                  const url = window.location.href
                  const campaignId = url.substring(url.lastIndexOf("/") + 1)
                  setFocusedKol(null)
                  clearKolWorkItem()
                  setFocusedTab(TabId.Insights)
                  const campaignInfo = fetchCampaignInfo(campaignId, debouncedSearchQuery)
                  campaignInfo.then((result) => {
                    setCampaignKols(result)
                    setLoading(false)
                    toast.success("Insights report updated successfully")
                  })
                })
                .catch((error) => {
                  setLoading(false)
                  toast.error("Failed to update insights report")
                })
            }
          }}
        />
      )}
      {loading && <LoadingIndicator />}
    </Edit>
  )
}

const OneLine = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;

  & > * {
    flex: 1;
  }
`

const TabsList = styled(Tabs.List)`
  display: flex;
  & > * {
    flex: 1;
  }
  margin-bottom: 16px;
`

const TabsTrigger = styled(Tabs.Trigger)`
  padding: 14px 0;
  color: #1d1b20;
  font-weight: 600;
  border: none;
  background-color: rgba(29, 27, 32, 0.12);
  cursor: pointer;

  &[data-state="active"] {
    background-color: #fef7ff;
  }
`

const TabsContent = styled(Tabs.Content)`
  padding: 16px;
  border: 1px solid #cac4d0;
  background: #fff;
`
