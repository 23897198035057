import React, { useRef, useState } from 'react';
import './ImageSelector.css';
import { InsightImage, LocalInsightFile, WorkItemData } from './Models';
import { ReactComponent as IconPlus } from '../../assets/ic_plus.svg';

interface ImageSelectorProps {
    workItemData: WorkItemData;
    images: InsightImage[];
    onImageAdded: (workItemData: WorkItemData, image: InsightImage) => void;
    onImageRemoved: (workItemData: WorkItemData, imageIndex: number) => void;

}

const ImageSelector: React.FC<ImageSelectorProps> = ({ workItemData, images, onImageAdded, onImageRemoved }) => {

    const inputRef = useRef<HTMLInputElement>(null);

    const handleAddImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                const localInsightFile = new LocalInsightFile();
                localInsightFile.file = file;
                localInsightFile.data = reader.result as string;
                onImageAdded(workItemData, { id: '', url: undefined, file: localInsightFile });
            };

            reader.readAsDataURL(file);
        }
    };

    const handleRemoveImage = (index: number) => {
        onImageRemoved(workItemData, index);
    };

    const triggerFileInput = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    return (
        <div className="image-selector">
            <div className="image-grid">
                <div className="image-item">
                    <label className="add-button" onClick={triggerFileInput}>
                        <IconPlus className="icon-plus" />
                    </label>
                    <input
                        type="file"
                        ref={inputRef}
                        accept="image/*"
                        onChange={handleAddImage}
                        style={{ display: 'none' }}
                    />
                    <div className="overlay" onClick={triggerFileInput}></div>
                </div>
                {images.map((image, index) => (
                    <div key={index} className="image-item">
                        <img src={image.url ?? image.file?.data ?? ""} alt={`Selected ${index}`} className="image" />
                        <button className="remove-button" onClick={() => handleRemoveImage(index)}>×</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImageSelector;