import React, { useEffect, useState } from 'react';
import './WorkItemForm.css'; // Import the CSS file
import IconInstagramNoColor from '../../assets/ic_instagram_no_color.svg';
import IconYoutubeNoColor from '../../assets/ic_youtube_no_color.svg';
import IconTiktokramNoColor from '../../assets/ic_tiktok_no_color.svg';
import IconTwitterNoColor from '../../assets/ic_twitter_no_color.svg';
import IconFacebookNoColor from '../../assets/ic_facebook_no_color.svg';
import IconDefaultSocialNoColor from '../../assets/ic_default_social_no_color.svg';

import { format } from 'date-fns';
import ic_calendar from '../../assets/ic_calendar.svg';
import ic_dolar from '../../assets/ic_dolar.svg';
import ContentMetricsField from './ContentMetricsField';
import ImageSelector from './ImageSelector';
import { CampaignKol, ContentType, getTierFromFanbase, getWorkItemType, InsightImage, postOnDateText, WorkItemData } from './Models';
import ContentMetricsForm from './ContentMetricsForm';
// @ts-ignore
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


interface WorkItemProps {
    data: WorkItemData;
    onDataChange: (data: WorkItemData) => void;
}

const WorkItemForm: React.FC<WorkItemProps> = ({ data, onDataChange }) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [showDatePicker, setShowDatePicker] = useState(false);

    const getStatusInText = () => {
        switch (data.workItem?.status) {
            case 'INSIGHTS_REJECTED':
                return 'Required to re-submit Insights Report';
            case 'PENDING_INSIGHTS':
                return 'Required to submit Insights Report';
            default:
                return '';
        }

    }

    const handleDateChange = (date: Date) => {
        setSelectedDate(date);
        setShowDatePicker(false);
        onDataChange({ ...data, kolInsight: { ...data.kolInsight, postOnDate: format(date, 'yyyy-MM-dd') } } as WorkItemData);
    };

    const onAddedImage = (data: WorkItemData, image: InsightImage) => {
        const newImages = [...data.kolInsight?.images ?? []];
        newImages.push(image);
        onDataChange({ ...data, kolInsight: { ...data.kolInsight, images: newImages } } as WorkItemData);
    };

    const onRemovedImage = (data: WorkItemData, index: number) => {
        let imageToRemove = data.kolInsight?.images?.[index];
        let imagesToRemove = data.kolInsight?.imagesToRemove ?? [];
        //Remove remote image
        if (imageToRemove?.id) {
            imagesToRemove.push(imageToRemove);
        }

        const newImages = [...data.kolInsight?.images ?? []];
        newImages.splice(index, 1);

        onDataChange({ ...data, kolInsight: { ...data.kolInsight, images: newImages, imagesToRemove } } as WorkItemData);
    }

    const handleButtonClick = () => {
        setShowDatePicker(true);
    };

    const platformIcon = () => {
        switch (data.workItem?.platform?.toLowerCase()) {
            case 'instagram':
                return IconInstagramNoColor;
            case 'youtube':
                return IconYoutubeNoColor;
            case 'tiktok':
                return IconTiktokramNoColor;
            case 'twitter':
                return IconTwitterNoColor;
            case 'facebook':
                return IconFacebookNoColor;
            default:
                return IconDefaultSocialNoColor;
        }
    }

    const hidden = () => {
        return (
            <div style={{ width: "0px", height: "0px" }}></div>
        );
    };

    if (data.kolInsight?.isLocal === true) {
        return hidden();
    }

    if (getWorkItemType(data.workItem?.code) == ContentType.none || getWorkItemType(data.workItem?.code) == ContentType.unknown) {
        return hidden();
    }

    return (
        <div className="contentMetricsArea">
            <div style={{ color: '#CE2C17', marginBottom: '10px' }}>{getStatusInText()}</div>
            <div className="areaTitle">{data.workItem ? data.workItem.description : ''}</div>
            <div style={{ paddingTop: '15px' }}>
                <a style={{ color: '#7B73F4', textDecoration: 'none' }} href={data.workItem ? data.workItem.postingLink : ''}>Deliverable link</a>
            </div>
            <div className="contentRow">
                <div>
                    <div style={{ color: '#717171', fontSize: '14px' }}>Platform</div>
                    <img src={platformIcon()} alt="platform" style={{ marginTop: '10px', width: '20px', height: '20px' }} />
                </div>
                <div style={{ width: '1px', height: '55px', backgroundColor: '#D9D9D9', marginLeft: '20px' }} />

                <div style={{ marginLeft: '20px' }}>
                    <div style={{ color: '#717171', fontSize: '14px' }}>Type</div>
                    <div style={{ color: 'black', fontSize: '14px', marginTop: '10px' }}>{data.workItem ? data.workItem.type : ''}</div>
                </div>
                <div style={{ width: '1px', height: '55px', backgroundColor: '#D9D9D9', marginLeft: '20px' }} />

                <div style={{ marginLeft: '20px' }}>
                    <div style={{ color: '#717171', fontSize: '14px' }}>Quantity</div>
                    <div style={{ color: 'black', fontSize: '14px', marginTop: '10px' }}>1</div>
                </div>
            </div>

            <div className="contentRow">
                <div className="contentField">
                    <ContentMetricsField
                        title="Spend Per Deliverable"
                        contentType="number"
                        content={(data.kolInsight?.spend ?? 0).toString()}
                        type="Spend"
                        handleInputChange={(e, type) => {
                            const newData = { ...data, kolInsight: { ...data.kolInsight, spend: parseInt(e.target.value) } } as WorkItemData;
                            onDataChange(newData);
                        }}
                        iconPath={ic_dolar}
                    />
                </div>
                <div className="contentField" style={{ marginLeft: '15px' }}>
                    <ContentMetricsField
                        title="Post On Date"
                        iconPath={ic_calendar}
                        contentType="text"
                        onIconClick={() => {
                            handleButtonClick();
                        }}
                        //content={data.kolInsight?.postOnDate ?? ''}
                        content={postOnDateText(data)}
                        type="PostOnDate"
                        handleInputChange={(e, type) => { }}
                    />
                    {showDatePicker && (
                        <div
                            className="shadow-layer"
                            onClick={() => {
                                setShowDatePicker(false);
                            }}
                        ></div>
                    )}
                    {showDatePicker && (
                        <div className="datepicker-popup">
                            <DatePicker selected={selectedDate} onChange={handleDateChange} inline />
                        </div>
                    )}
                </div>
            </div>

            <div
                style={{
                    width: 'calc(100% - 20px)',
                    height: '1px',
                    backgroundColor: '#D9D9D9',
                    marginLeft: '10px',
                    marginRight: '10px',
                    marginTop: '20px',
                }}
            />
            <div style={{ paddingTop: '15px', fontWeight: 'bold', color: '#5770C6' }}>Reach</div>

            <div className="contentRow">
                <div className="contentField">
                    <ContentMetricsField
                        title="Reach"
                        contentType="number"
                        iconPath={null}
                        content={(data.kolInsight?.reach ?? 0).toString()}
                        type="Reach"
                        handleInputChange={(e, type) => {
                            const newData = { ...data, kolInsight: { ...data.kolInsight, reach: parseInt(e.target.value) } } as WorkItemData;
                            onDataChange(newData);
                        }}
                    />
                </div>
                <div className="contentField" style={{ marginLeft: '15px' }}>
                    <ContentMetricsField
                        title="View"
                        contentType="number"
                        iconPath={null}
                        content={(data.kolInsight?.view ?? 0).toString()}
                        type="View"
                        handleInputChange={(e, type) => {
                            const newData = { ...data, kolInsight: { ...data.kolInsight, view: parseInt(e.target.value) } } as WorkItemData;
                            onDataChange(newData);
                        }}
                    />
                </div>
            </div>

            {/* Title post */}
            {getWorkItemType(data.workItem?.code) == ContentType.post && <div style={{ paddingTop: '15px', fontWeight: 'bold', color: '#5770C6' }}>Post Engagement</div>}

            {/* Like && comment */}
            {getWorkItemType(data.workItem?.code) == ContentType.post && <div className="contentRow">
                <div className="contentField">
                    <ContentMetricsField
                        title="Like"
                        contentType="number"
                        iconPath={null}
                        content={(data.kolInsight?.like ?? 0).toString()}
                        type="Like"
                        handleInputChange={(e, type) => {
                            const newData = { ...data, kolInsight: { ...data.kolInsight, like: parseInt(e.target.value) } } as WorkItemData;
                            onDataChange(newData);
                        }}
                    />
                </div>
                <div className="contentField" style={{ marginLeft: '15px' }}>
                    <ContentMetricsField
                        title="Comment"
                        contentType="number"
                        iconPath={null}
                        content={(data.kolInsight?.comment ?? 0).toString()}
                        type="Comment"
                        handleInputChange={(e, type) => {
                            const newData = { ...data, kolInsight: { ...data.kolInsight, comment: parseInt(e.target.value) } } as WorkItemData;
                            onDataChange(newData);
                        }}
                    />
                </div>
            </div>}
            {/* Share && save */}
            {getWorkItemType(data.workItem?.code) == ContentType.post && <div className="contentRow">
                <div className="contentField">
                    <ContentMetricsField
                        title="Share"
                        contentType="number"
                        iconPath={null}
                        content={(data.kolInsight?.share ?? 0).toString()}
                        type="Share"
                        handleInputChange={(e, type) => {
                            const newData = { ...data, kolInsight: { ...data.kolInsight, share: parseInt(e.target.value) } } as WorkItemData;
                            onDataChange(newData);
                        }}
                    />
                </div>
                <div className="contentField" style={{ marginLeft: '15px' }}>
                    <ContentMetricsField
                        title="Save"
                        iconPath={null}
                        content={(data.kolInsight?.save ?? 0).toString()}
                        type="Save"
                        contentType="number"
                        handleInputChange={(e, type) => {
                            const newData = { ...data, kolInsight: { ...data.kolInsight, save: parseInt(e.target.value) } } as WorkItemData;
                            onDataChange(newData);
                        }}
                    />
                </div>
            </div>}

            {/* Title story */}
            {getWorkItemType(data.workItem?.code) == ContentType.story && <div style={{ paddingTop: '15px', fontWeight: 'bold', color: '#5770C6' }}>Story Engagement</div>}
            {/* Sticker tap && Link click */}
            {getWorkItemType(data.workItem?.code) == ContentType.story && <div className="contentRow">
                <div className="contentField">
                    <ContentMetricsField
                        title="Sticker Tap"
                        contentType="number"
                        iconPath={null}
                        content={(data.kolInsight?.sticker_tap ?? 0).toString()}
                        type="Sticker Tap"
                        handleInputChange={(e, type) => {
                            const newData = { ...data, kolInsight: { ...data.kolInsight, sticker_tap: parseInt(e.target.value) } } as WorkItemData;
                            onDataChange(newData);
                        }}
                    />
                </div>
                <div className="contentField" style={{ marginLeft: '15px' }}>
                    <ContentMetricsField
                        title="Link Click"
                        contentType="number"
                        iconPath={null}
                        content={(data.kolInsight?.link_click ?? 0).toString()}
                        type="Link Click"
                        handleInputChange={(e, type) => {
                            const newData = { ...data, kolInsight: { ...data.kolInsight, link_click: parseInt(e.target.value) } } as WorkItemData;
                            onDataChange(newData);
                        }}
                    />
                </div>
            </div>}
            {/* Like && Share */}
            {getWorkItemType(data.workItem?.code) == ContentType.story && <div className="contentRow">
                <div className="contentField">
                    <ContentMetricsField
                        title="Like"
                        contentType="number"
                        iconPath={null}
                        content={(data.kolInsight?.sticker_tap ?? 0).toString()}
                        type="Like"
                        handleInputChange={(e, type) => {
                            const newData = { ...data, kolInsight: { ...data.kolInsight, like: parseInt(e.target.value) } } as WorkItemData;
                            onDataChange(newData);
                        }}
                    />
                </div>
                <div className="contentField" style={{ marginLeft: '15px' }}>
                    <ContentMetricsField
                        title="Share"
                        contentType="number"
                        iconPath={null}
                        content={(data.kolInsight?.link_click ?? 0).toString()}
                        type="Share"
                        handleInputChange={(e, type) => {
                            const newData = { ...data, kolInsight: { ...data.kolInsight, share: parseInt(e.target.value) } } as WorkItemData;
                            onDataChange(newData);
                        }}
                    />
                </div>
            </div>}
            {/* Reply */}
            {getWorkItemType(data.workItem?.code) == ContentType.story && <div className="contentRow">
                <div className="contentField">
                    <ContentMetricsField
                        title="Reply"
                        contentType="number"
                        iconPath={null}
                        content={(data.kolInsight?.reply ?? 0).toString()}
                        type="Reply"
                        handleInputChange={(e, type) => {
                            const newData = { ...data, kolInsight: { ...data.kolInsight, reply: parseInt(e.target.value) } } as WorkItemData;

                            onDataChange(newData);
                        }}
                    />
                </div>

            </div>}

            {/* Title livestream */}
            {getWorkItemType(data.workItem?.code) == ContentType.livestream && <div style={{ paddingTop: '15px', fontWeight: 'bold', color: '#5770C6' }}>Live Stream Engagement</div>}
            {/* Like share */}
            {getWorkItemType(data.workItem?.code) == ContentType.livestream && <div className="contentRow">
                <div className="contentField">
                    <ContentMetricsField
                        title="Like"
                        contentType="number"
                        iconPath={null}
                        content={(data.kolInsight?.sticker_tap ?? 0).toString()}
                        type="Like"
                        handleInputChange={(e, type) => {
                            const newData = { ...data, kolInsight: { ...data.kolInsight, like: parseInt(e.target.value) } } as WorkItemData;
                            onDataChange(newData);
                        }}
                    />
                </div>
                <div className="contentField" style={{ marginLeft: '15px' }}>
                    <ContentMetricsField
                        title="Share"
                        contentType="number"
                        iconPath={null}
                        content={(data.kolInsight?.link_click ?? 0).toString()}
                        type="Share"
                        handleInputChange={(e, type) => {
                            const newData = { ...data, kolInsight: { ...data.kolInsight, comment: parseInt(e.target.value) } } as WorkItemData;
                            onDataChange(newData);
                        }}
                    />
                </div>
            </div>}
            {/* Time */}
            {getWorkItemType(data.workItem?.code) == ContentType.livestream && <div className="contentRow">
                <div className="contentField">
                    <ContentMetricsField
                        title="Total time"
                        contentType="number"
                        iconPath={null}
                        content={(data.kolInsight?.sticker_tap ?? 0).toString()}
                        type="Total time"
                        handleInputChange={(e, type) => {

                        }}
                    />
                </div>

            </div>}

            <ImageSelector key={data.kolInsight?.metricId}
                workItemData={data}
                images={data.kolInsight?.images ?? []}
                onImageAdded={(workItemData, image) => {
                    onAddedImage(workItemData, image);
                }}
                onImageRemoved={(workItemData, index) => {
                    onRemovedImage(workItemData, index);
                }}
            />


        </div>
    );
};

export default WorkItemForm;
